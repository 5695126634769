import React from 'react';
import styled from 'styled-components';
import DesignTokens from '@audi/audi-ui-design-tokens';
import { Button, Text, Theme } from '@audi/audi-ui-react';
import { renderTextWithFootnotesReferencesV2 } from '@oneaudi/feature-app-utils';

import { EventItem } from '../../../types';
import { Tracking } from '../../utils/useTracking';
import { Space } from './StyledSpace';

const Container = styled.div`
  sup {
    // footnotes too small fix
    font-size: 10px !important;
  }

  .event-title sup {
    // footnotes too small fix
    vertical-align: super;
    font-size: 16px !important;
  }

  width: 100%;
  @media (max-width: ${DesignTokens.BREAKPOINT_S}px) {
    max-width: 400px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  &:first-child {
    flex: 2;
  }
`;

const Column = styled.div`
  flex: 2;
  ${({ theme }: { theme: Theme }) => {
    return `
      padding: var(${theme.responsive.spacing.xs}) 0;
  `;
  }}
  box-sizing: border-box;

  &:first-child {
    flex: 2;
    ${({ theme }: { theme: Theme }) => {
      return `
        padding: var(${theme.responsive.spacing.xs}) var(${theme.responsive.spacing.xs}) var(${theme.responsive.spacing.s}) 0;
    `;
    }}
  }

  ${Row}:last-child & {
    align-items: flex-end;
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: ${DesignTokens.BREAKPOINT_M}px) {
    flex: 100%;

    ${Row}:last-child & {
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
`;

const EventDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;

  ${({ theme }: { theme: Theme }) => {
    return `
      gap: var(${theme.responsive.spacing.xxs});
    `;
  }}
`;

const formatDateString = (
  date?: string,
): { day: string; month: string; year: string } | undefined => {
  if (!date) {
    return undefined;
  }

  const formattedDate = new Date(date);
  const dayStr =
    formattedDate.getDate() > 9
      ? formattedDate.getDate().toString()
      : `0${formattedDate.getDate()}`;
  const yearStr = formattedDate.getFullYear().toString();
  const monthAsString = formattedDate.toLocaleDateString('default', { month: 'long' });

  return {
    day: dayStr,
    month: monthAsString,
    year: yearStr,
  };
};

export interface EventTeaserItemProps {
  event: EventItem;
  tracking: Tracking;
}

export const EventTeaserItem: React.FC<EventTeaserItemProps> = (props) => {
  const { event, tracking } = props;
  const startDate = formatDateString(event.startDate);
  const endDate = formatDateString(event.endDate);

  return (
    <Container>
      <Row>
        <Column>
          <Text as="h3" className="event-title" variant="order1">
            {renderTextWithFootnotesReferencesV2(event.eventTitle)}
          </Text>
        </Column>
        <Column>
          <Text variant="copy1">{renderTextWithFootnotesReferencesV2(event.description)}</Text>
        </Column>
      </Row>
      <Row>
        <Column>
          <EventDetailsWrapper>
            <Text variant="copy1">
              {`${startDate?.day} ${startDate?.month} ${startDate?.year}`}
              {event.endDate && ` - ${endDate?.day} ${endDate?.month} ${endDate?.year}`}
            </Text>
            {event.location && (
              <Text variant="copy1">{renderTextWithFootnotesReferencesV2(event.location)}</Text>
            )}
          </EventDetailsWrapper>
        </Column>
        <Column>
          {event.cta && event.cta.url && event.cta.label && <Space size="l" />}
          {event.cta && event.cta.url && event.cta.label && (
            <Button
              href={event.cta.url}
              aria-label={event.cta.ariaLabel}
              newWindow={event.cta.openInNewTab}
              variant="secondary"
              onClick={() =>
                tracking.sendClickEvent(
                  event.cta.label,
                  event.cta.label || '',
                  `${event.eventTitle || ''}~${event.location || ''}`,
                )
              }
            >
              {event.cta.label}
            </Button>
          )}
        </Column>
      </Row>
    </Container>
  );
};
