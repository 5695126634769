import { ContentServiceV1 } from '@oneaudi/content-service';
import { InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';
import { AppContent, EditorJsonContent, FalconContent } from '../../types';

const DEFAULT_THEME_OPTION = 'Light';

function isFalconContent(content: FalconContent): content is FalconContent {
  // Check here for all required fields and return true, otherwise false
  return Boolean(isContentFragment(content));
}

export function mapContent(contentService: ContentServiceV1): AppContent {
  const content = contentService.getContent() as EditorJsonContent | FalconContent;

  if (content.__type === 'aem-headless') {
    // Perform runtime validation
    if (!isFalconContent(content)) {
      // Throw `InvalidContentError` here if provided content
      // is not valid. This will display as a special error message
      // in the frontend on author environments.
      throw new InvalidContentError('Content is invalid.');
    }

    // map CF content to internal structure
    return mapFalconContent(content);
  }

  // map FACE content to internal structure
  return mapEditorJsonContent(content);
}

/**
 * Map EditorJsonContent to AppContent
 *
 * @param content EditorJsonContent
 * @returns AppContent
 */
export function mapEditorJsonContent(content: EditorJsonContent): AppContent {
  return {
    theme: content.themeOption?.theme || DEFAULT_THEME_OPTION,
    headline: content.headingOption.headline,
    events: content.eventsOption?.events || [],
  };
}

/**
 * Map FalconContent to AppContent
 *
 * @param content FalconContent
 * @returns AppContent
 */
export function mapFalconContent(content: FalconContent): AppContent {
  return {
    theme: content.fields.themeOption_theme || DEFAULT_THEME_OPTION,
    headline: content.fields.headingOption_headline,
    events: content.fields.eventsOption_events.map((item) => ({
      eventTitle: item.fields.eventTitle,
      description: item.fields.description,
      startDate: item.fields.startDate,
      endDate: item.fields.endDate,
      location: item.fields.location,
      cta: {
        label: item.fields.cta_label,
        url: item.fields.cta_url,
        ariaLabel: item.fields.cta_ariaLabel,
        openInNewTab: item.fields.cta_openInNewTab,
      },
    })),
  };
}
