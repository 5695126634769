import React from 'react';
import styled from 'styled-components';
import { audiLightTheme, audiDarkTheme, Text, Theme, ThemeProvider } from '@audi/audi-ui-react';

import { AppContent, FA_LOCATOR_ID } from '../../../types';
import { useTracking } from '../../utils/useTracking';
import { EventTeaserItem } from './EventTeaserItem';
import { Space } from './StyledSpace';
import { sortEvents } from '../../utils/sortEvents';
import { filterEvents } from '../../utils/filterEvents';

const Container = styled.div`
  ${({ theme }: { theme: Theme }) => {
    return `
      background-color: var(${theme.colors.background.level[0]});
      padding: ${theme.name === 'Audi Dark Theme' ? `var(${theme.responsive.spacing.xl})` : '0'};

      margin: var(${theme.responsive.spacing.xxl}) 0;

      @media (min-width: ${theme.breakpoints.s}px) {
        margin-left: var(${theme.responsive.spacing.pageMargin});
        margin-right: var(${theme.responsive.spacing.pageMargin});
      }
    `;
  }}
`;

const EventTeaserItems = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }: { theme: Theme }) => {
    return `
      gap: var(${theme.responsive.spacing.xxxl});
    `;
  }}
`;

export const EventTeaser: React.FC<AppContent> = (props) => {
  const { theme, headline, events: rawEvents } = props;

  const ref = React.useRef(null);

  const tracking = useTracking(ref, {
    implementer: 37,
  });

  React.useEffect(() => {
    tracking.sendReadyEvent();
    tracking.registerImpressionTracking();
  }, []);

  const events = rawEvents && rawEvents.length > 0 ? sortEvents(filterEvents(rawEvents)) : [];

  return events.length > 0 ? (
    <ThemeProvider theme={theme === 'Dark' ? audiDarkTheme : audiLightTheme}>
      <Container ref={ref} data-test-id={FA_LOCATOR_ID}>
        <Text as="h2" variant="order1">
          {headline}
        </Text>

        <Space size="xl" />
        <EventTeaserItems>
          {events.map((event, i) => (
            <EventTeaserItem key={`event-teaser-${String(i)}`} event={event} tracking={tracking} />
          ))}
        </EventTeaserItems>
      </Container>
    </ThemeProvider>
  ) : null;
};
