import { useContext } from 'react';
import { Context } from '../Context';
import { isTrackingDisabled, debug } from './debugging';
import { getInstanceIndexNo } from './getInstanceIndexNo';

export interface Tracking {
  sendClickEvent(linkLabel: string, linkUrl: string, eventName: string): void;
  sendReadyEvent(): void;
  registerImpressionTracking(): void;
}

export function useTracking(
  ref: React.MutableRefObject<null>,
  attributes?: {
    version?: string;
    implementer?: number;
    [key: string]: unknown;
  },
): Tracking {
  if (isTrackingDisabled()) {
    debug('TRACKING DISABLED');

    return {
      sendClickEvent: (linkLabel: string, linkUrl: string, eventName: string) => {
        debug('TRACKING not executed: sendClickEvent', linkLabel, linkUrl, eventName);
      },
      sendReadyEvent: () => {
        debug('TRACKING not executed: sendReadyEvent');
      },
      registerImpressionTracking: () => {
        debug('TRACKING not executed: registerImpressionTracking');
      },
    };
  }

  // eslint-disable-next-line prefer-rest-params
  debug('Tracking: initialized', arguments);

  const { trackingService } = useContext(Context);
  const featureAppName = (typeof __FEATURE_APP_NAME__ === 'string' && __FEATURE_APP_NAME__) || '';
  const componentNameWithDashes = featureAppName.replace('fa-', '');
  const componentNameWithSpaces = componentNameWithDashes.replace(/-/g, ' ');
  const defaultComponentAdditions = {
    componentInfo: {
      // not accepted by tracking lib
      // componentID: 'bla',
    },
    attributes: {
      version: __FEATURE_APP_VERSION__,
      ...attributes,
    },
  };

  return {
    sendClickEvent: (linkLabel: string, linkUrl: string, eventName: string) => {
      if (trackingService) {
        const eventAction = trackingService.evaluateLinkType(linkUrl);

        debug('Tracking: sendClickEvent', linkLabel, linkUrl, eventName);
        trackingService.track({
          event: {
            attributes: {
              componentName: componentNameWithDashes,
              elementName: 'text link',
              label: linkLabel,
              clickID: '',
              pos: '',
              targetURL: linkUrl,
              value: eventName,
            },
            eventInfo: {
              eventAction: eventAction || 'content',
              eventName: `${componentNameWithSpaces} - click`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        });
      }
    },
    sendReadyEvent: () => {
      if (trackingService) {
        const readyEvent = {
          event: {
            attributes: {
              componentName: componentNameWithDashes,
              elementName: '',
              label: '',
              clickID: '',
              pos: '',
              targetURL: '',
              value: '',
            },
            eventInfo: {
              eventAction: 'feature_app_ready',
              eventName: `${componentNameWithSpaces} - feature app ready`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };

        debug('Tracking: sendReadyEvent', readyEvent);
        trackingService.track(readyEvent);
      }
    },
    registerImpressionTracking: () => {
      if (trackingService && ref.current) {
        debug('Tracking: registerImpressionTracking');
        trackingService.registerImpressionTracking(ref.current, () => {
          return {
            event: {
              attributes: {
                componentName: componentNameWithDashes,
                elementName: '',
                label: '',
                clickID: '',
                pos: getInstanceIndexNo(ref).toString(),
                targetURL: '',
              },
              eventInfo: {
                eventAction: 'impression',
                eventName: `${componentNameWithSpaces} - impression`,
              },
            },
            componentUpdate: defaultComponentAdditions,
          };
        });
      }
    },
  };
}
