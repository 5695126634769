import { EventItem } from '../../types';

const today = new Date();

const dateIsTodayOrFuture = (date?: EventItem['startDate' | 'endDate']) => {
  if (!date) return false;
  const eventDate = new Date(date);
  return eventDate.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0);
};

export const filterEvents = (events: EventItem[]): EventItem[] => {
  const filteredEvents = events.filter((event) => {
    return dateIsTodayOrFuture(event.startDate) || dateIsTodayOrFuture(event.endDate);
  });
  return filteredEvents;
};
