/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import styled from 'styled-components';
import { SpaceSize } from '../../../types';

export interface SpaceProps {
  size: SpaceSize;
}
export const Space = styled.div<SpaceProps>`
  ${(props) => {
    return `
        height: var(${props.theme.responsive.spacing[props.size]});
      `;
  }}
`;
